import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClientCategorysExportCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/clientcategory/exportcategory", {'params' : data, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientCategorysExportSimple(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/clientcategory/exportsimple", {'params' : data, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientCategorysExport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/clientcategory/export", {'params' : data, responseType: 'blob'} )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientCategorys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/clientcategory/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/clientcategory/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClientCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/clientcategory", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteControllingPerson(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/clientcategory/controlling_person", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientCategoryDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/clientcategory/clientcategory_details", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteBank(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/clientcategory/clientcategory_bank", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClientCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/clientcategory", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSort(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/clientcategory", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
