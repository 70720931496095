<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">CLIENT CATEGORY</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Search Team" />
          </div>
          <b-button v-on:click="onBtnExportSimpleCategory()" class="mr-1 btn-primary-green mobile-w100">
            <feather-icon icon="FileTextIcon" class="mr-50" size="16" />
          </b-button>
        </div>
      </div>

      <b-table ref="refClientCategoryListTable" class="position-relative" :items="fetchClientCategorys" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">



        <!-- Column: Show Detail -->
        <template #cell(account_types)="row">
          <b-button v-if="row.item.account_types.length > 0" size="sm" @click="row.toggleDetails" class="mr-2"
            variant="primary">
            {{ row.detailsShowing ? "Hide" : "Show" }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card v-if="row.item.account_types">
            <b-row>
              <b-badge v-for="(item, index) in row.item.account_types" :key="index" class="text-capitalize m-1"
                style="display: inline" :variant="item.is_approved == 1 ? 'primary' : 'warning'">
                <span v-if="item.is_approved == 0">
                  {{ item.name_en }}
                </span>
                <span v-else>
                  {{ item.name_en }}
                </span>
              </b-badge>
            </b-row>
          </b-card>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item v-if="ability.can('view', 'clientcategory_account')"
              :to="{ name: 'clientcategorys-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'clientcategory_account')"
              :to="{ name: 'clientcategorys-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item v-if="data.item.is_company && ability.can('update', 'clientcategory_account')" >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Select Birthday Email</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalClientCategorys" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useClientCategorysList from "./useClientCategorysList";
import clientcategoryStoreModule from "../clientcategoryStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    onBtnExportSimpleCategory() {
      console.log('client export')
      store
        .dispatch('app-clientcategory/fetchClientCategorysExportCategory')
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Clients Category Export List`);
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    triggerResetPasswordModal(item) {
      console.log("triggerResetPasswordModal", item);
      this.clientcategoryInfo.id = item.id;
      this.clientcategoryInfo.name = item.name;
      this.clientcategoryInfo.password = "";
      this.clientcategoryInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-clientcategory-password");
    },
  },
  data() {
    return {
      clientcategoryInfo: { id: 0, password: "", password_confirmation: "" },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-clientcategory";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientcategoryStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchClientCategorys,
      tableColumns,
      perPage,
      currentPage,
      totalClientCategorys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientCategoryListTable,
      refetchData,
      ability,
      statusFilter,
    } = useClientCategorysList();

    return {
      // Sidebar
      fetchClientCategorys,
      tableColumns,
      perPage,
      currentPage,
      totalClientCategorys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientCategoryListTable,
      refetchData,
      ability,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
